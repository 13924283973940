<template>
  <div class="RefundInformation">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn2">
          <span style="height: 40px; line-height: 40px">赛事名称：</span>
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="raceName"
          ></el-input>
          <span style="height: 40px; line-height: 40px">审核状态：</span>
          <el-select
            v-model="statusRefund"
            placeholder="请选择"
            style="width: 200px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="审核中" value="0"></el-option>
            <el-option label="审核成功" value="1"></el-option>
            <el-option label="审核失败" value="2"></el-option>
          </el-select>
          <el-button
            style="height: 40px; margin-left: 10px"
            type="primary"
            plain
            @click="searchSchedule"
            >查询</el-button
          >
          <!-- <el-button
            style="height: 40px"
            type="primary"
            plain
            @click="exportApplyRefund"
            >导出</el-button
          > -->
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="600"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="赛事名称" width="300">
            <template slot-scope="scope">
              <span>{{ scope.row.competitionName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位名称" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.unitName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户名" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户手机号" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请时间" width="170">
            <template slot-scope="scope">
              <span>{{ scope.row.applyTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收件人" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.contactName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收件人电话" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.contactPhone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收件人城市" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.areaName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="奖品状态" width="80">
            <template slot-scope="scope">
              <span>{{
                scope.row.status == 1
                  ? "审核成功"
                  : scope.row.status == 2
                  ? "审核失败"
                  : scope.row.status == 0
                  ? "审核中"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="warning"
                @click="checkRefund(scope.$index, scope.row)"
                >审核</el-button
              >
              <!-- <el-button
                size="mini"
                type="info"
                disabled
                v-else-if="
                  scope.row.auditStatus != 0 && scope.row.applyType == 1
                "
                >已审核</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      width="50%"
      @close="dialogClose"
    >
      <el-form :model="formModel" label-width="120px">
        <el-form-item label="赛事名称:">
          <el-input
            :disabled="true"
            v-model="formModel.competitionName"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人:">
          <el-input :disabled="true" v-model="formModel.contactName"></el-input>
        </el-form-item>
        <el-form-item label="收件人联系电话:">
          <el-input
            :disabled="true"
            v-model="formModel.contactPhone"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件人城市:">
          <el-input :disabled="true" v-model="formModel.areaName"></el-input>
        </el-form-item>
        <el-form-item label="收件人详细地址:">
          <el-input :disabled="true" v-model="formModel.address"></el-input>
        </el-form-item>
        <el-form-item label="照片:">
          <el-image
            v-for="(img, index) in formModel.pictures"
            :key="index"
            style="width: 100px; height: 100px"
            :src="img"
            :preview-src-list="formModel.pictures"
            fit="cover"
          ></el-image>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            :disabled="true"
            v-model="remarks"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核结果:">
          <el-input type="textarea" v-model="result"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="success" @click="confirm(1)">同意</el-button>
        <el-button type="danger" @click="confirm(2)">不同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formModel: {},
      applyReason: "",
      remarks: "",
      result: "",
      RefundId: "",
      statusRefund: "",
      refundType: "",
      raceName: "",
      playerName: "",
      startTime: "",
      endTime: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      dialogVisible: false,
    };
  },
  methods: {
    dialogClose() {},
    handleSizeChange(val) {
      this.pageSize = val;
      this.findRefundInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.findRefundInfo();
    },
    //获取所有信息
    async findRefundInfo() {
      try {
        const result = await this.$api.query(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item && item.playerNames) {
            item.playerNames = JSON.parse(item.playerNames).join(",");
          }
        });
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //查询信息
    async searchSchedule() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.query(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&search_LIKE_competitionName=${this.raceName}&search_EQ_status=${this.statusRefund}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.tableData.forEach((item) => {
          if (item && item.playerNames) {
            item.playerNames = JSON.parse(item.playerNames).join(",");
          }
        });
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    checkRefund(index, row) {
      this.remarks = row.remarks;
      this.result = row.result;
      this.RefundId = row.id;
      this.formModel = JSON.parse(JSON.stringify(row));
      this.formModel.pictures = JSON.parse(this.formModel.pictures);
      this.dialogVisible = true;
    },
    async confirm(status) {
      console.log("aaaa");
      if (status == 1) {
        if (!this.result) {
          this.$message({
            type: "warning",
            message: "请输入审核结果",
          });
        } else {
          this.dialogVisible = false;
          try {
            const results = await this.$api.check(
              `id=${this.RefundId}&status=${status}&result=${
                this.result ? this.result : ""
              }`
            );
            console.log(results);
            this.findRefundInfo();
          } catch (error) {
            console.log(error);
          }
        }
      } else if (status == 2) {
        if (!this.result) {
          this.$message({
            type: "warning",
            message: "请输入审核结果",
          });
        } else {
          this.dialogVisible = false;
          try {
            const results = await this.$api.check(
              `id=${this.RefundId}&status=${status}&result=${
                this.result ? this.result : ""
              }`
            );
            console.log(results);
            this.findRefundInfo();
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    // async exportApplyRefund() {
    //   var a = document.createElement("a");
    //   document.body.appendChild(a);
    //   a.style = "display: none";
    //   try {
    //     const result = await this.$api.exportApplyRefund(
    //       `search_EQ_refundStatus=${
    //         this.statusRefund
    //       }&search_LIKE_competitionName=${
    //         this.raceName
    //       }&search_LIKE_teamNamePlayerName=${
    //         this.playerName
    //       }&search_EQ_applyDateStart=${
    //         this.startTime ? this.startTime : ""
    //       }&search_EQ_applyDateEnd=${
    //         this.endTime ? this.endTime : ""
    //       }&search_EQ_applyType=${this.refundType}`
    //     );
    //     console.log(result);
    //     let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
    //     let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
    //     console.log(decodeURI(fileName), result);
    //     if (result.data) {
    //       let blob = new Blob([result.data], {
    //         type: "application/vnd.ms-excel",
    //       });
    //       let objectUrl = URL.createObjectURL(blob); //创建URL
    //       a.href = objectUrl;
    //       a.download = decodeURI(fileName);
    //       a.click();
    //       URL.revokeObjectURL(objectUrl); // 释放内存
    //       setTimeout(() => {
    //         document.body.removeChild(a);
    //       }, 2000);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },

  created() {
    this.findRefundInfo();
  },
};
</script>

<style lang="less">
.RefundInformation {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
      .btn2 {
        display: flex;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 150px;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
